// window.location.href = $(e.currentTarget).find('a').attr('href')

import {Controller} from 'stimulus'

export default class extends Controller {

  onClick(e){
    window.location.href = e.currentTarget.querySelector('a').getAttribute('href')
  }
}
